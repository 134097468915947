<template>
    <div class="px-10 w-4/6">
        <div class="flex gap-5 py-10">
            <div class="w-1/2 flex gap-5">
                <input
                    type="text"
                    class="w-full px-5 py-2 rounded border"
                    placeholder="Enter voucher title"
                    v-model="keyword"
                >
                <button
                    type="button"
                    class="px-5 py-3 bg-peach text-white rounded"
                    @click="fetchVouchers"
                >
                    Search
                </button>
            </div>
        </div>

        <div class="bg-peach flex font-medium items-center p-5 rounded-t-lg text-white">
            <div class="w-1/4">Title</div>
            <div class="w-1/4">Seller</div>
            <div class="w-1/4">Value</div>
            <div class="w-1/4 text-center">Action</div>
        </div>

        <template v-if="vouchers.total !== 0">
            <div
                v-for="(voucher, index) in vouchers.data"
                class="flex gap-5 items-center p-5 text-sm"
                :class="{ 'bg-white' : !(index % 2), 'bg-gray-100' : index % 2, 'rounded-b-lg' : index == vouchers.data.length - 1 }"
                :key="voucher.id"
            >
                <div class="font-bold w-1/4">{{ voucher.title }}</div>
                <div class="w-1/4">{{ voucher.seller.company.name }}</div>
                <div class="w-1/4">
                    {{ values[voucher.id] }}
                </div>
                <div class="w-1/4">
                    <button
                        class="py-2 rounded text-sm text-white w-full"
                        :class="{ 'bg-green-600' : !voucher.is_active, 'bg-black' : voucher.is_active }"
                        @click="updateValue(voucher.id, voucher.is_active)"
                    >
                        {{ voucher.is_active ? 'Deactivate' : 'Activate' }}
                    </button>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="text-center text-2xl mt-3">
                No data found.
            </div>
        </template>

        <template v-if="vouchers.total !== 0">
            <TailwindPagination
                :data="vouchers"
                @pagination-change-page="fetchVouchers"
            />
        </template>
    </div>
</template>

<script>
    import TailwindPagination from 'laravel-vue-pagination'

    export default {
        components: {
            TailwindPagination
        },
        data() {
            return {
                vouchers: [],
                limit: 10,
                page: 1,
                values: [],
                keyword: ''
            }
        },
        mounted() {
            this.fetchVouchers()
        },
        methods: {
            async fetchVouchers(page = undefined) {
                try {
                    await this.$store.commit('SET_EMPLOYEE_VOUCHERS', [])
                    await this.$store.commit('SET_IS_LOADING', { status: 'open' })

                    const { vouchers } = await this.$store.dispatch('FETCH_EMPLOYEE_VOUCHERS', {
                        pagination: this.limit,
                        page: page ?? this.page,
                        keyword: this.keyword
                    })

                    this.vouchers = vouchers

                    if(vouchers.total) {
                        vouchers.data.forEach((data) => {
                            this.values[data.id] = data.employee_value
                        })
                    }

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                } catch(error) {
                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                }
            },
            async updateValue(id, status) {
                try {
                    const data = await this.$store.dispatch(
                        'UPDATE_EMPLOYEE_VOUCHER_VALUE',
                        {
                            id: id,
                            value: status
                        }
                    )

                    this.page = 1
                    this.keyword = ''
                    this.fetchVouchers()

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                } catch(error) {
                    console.log('error', error)

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                }
            }
        }
    }
</script>

<style>
    ul.pagination {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 20px; 
    }
</style>